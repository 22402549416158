// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-post-layout-js": () => import("./../src/components/postLayout.js" /* webpackChunkName: "component---src-components-post-layout-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cesta-pitanja-js": () => import("./../src/pages/cesta-pitanja.js" /* webpackChunkName: "component---src-pages-cesta-pitanja-js" */),
  "component---src-pages-conditions-js": () => import("./../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-dogovori-sastanak-js": () => import("./../src/pages/dogovori-sastanak.js" /* webpackChunkName: "component---src-pages-dogovori-sastanak-js" */),
  "component---src-pages-hvala-na-prijavi-js": () => import("./../src/pages/hvala-na-prijavi.js" /* webpackChunkName: "component---src-pages-hvala-na-prijavi-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-novosti-iz-turizma-i-vodici-za-iznajmljivace-js": () => import("./../src/pages/novosti-iz-turizma-i-vodici-za-iznajmljivace.js" /* webpackChunkName: "component---src-pages-novosti-iz-turizma-i-vodici-za-iznajmljivace-js" */),
  "component---src-pages-o-nama-js": () => import("./../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-preuzmi-besplatni-vodic-za-iznajmljivace-js": () => import("./../src/pages/preuzmi-besplatni-vodic-za-iznajmljivace.js" /* webpackChunkName: "component---src-pages-preuzmi-besplatni-vodic-za-iznajmljivace-js" */),
  "component---src-pages-vodjenje-apartmana-split-js": () => import("./../src/pages/vodjenje-apartmana-split.js" /* webpackChunkName: "component---src-pages-vodjenje-apartmana-split-js" */),
  "component---src-pages-zastita-podataka-js": () => import("./../src/pages/zastita-podataka.js" /* webpackChunkName: "component---src-pages-zastita-podataka-js" */)
}

